<template>
  <div class="ordonnance">
    <template v-if="!loader">
      <h1
        class="
        border-0
        font-semibold
        border-b border-solid border-gray-400
        py-3
        px-2
        text-blackdok text-xl
      "
        style="font-size: 20px"
      >
        Personnaliser mon ordonnance
      </h1>

      <!-- AVATAR -->
      <div class="w-full flex flex-col justify-center items-center mt-5 px-2">
        <avatar-upload
          :param-file.sync="doctorProfile.logo"
          :active-upload="true"
          :reSize="true"
          successMsg="Votre logo est téléchargé avec succès"
        ></avatar-upload>
      </div>

      <!-- CONTENT -->
      <div class="w-full flex flex-col px-2">
        <div class="header-content">
          <div class="flex flex-col mb-5 w-full mr-2 h-auto">
            <label class="mr-5 text-xl font-semibold text-blackdok"
              >Entête</label
            >
            <label class="pl-5 mr-5 text-gray-700 text-sm my-1"
              >Diplômes & expériences</label
            >
            <quill-editor
              :content="doctorProfile.prescription.header"
              placeholder="header Content"
              :options="editorOpions"
              @change="onEditorChangeHeader($event)"
            />
          </div>
        </div>
        <div class="footer-content">
          <div class="flex flex-col mb-5 w-full mr-2 h-auto">
            <label class="mr-5 text-xl font-semibold text-blackdok"
              >Pied de page</label
            >
            <label class="pl-5 mr-5 text-gray-700 text-sm my-1"
              >Adresse, téléphone, ...</label
            >
            <quill-editor
              :content="doctorProfile.prescription.footer"
              placeholder="Footer Content"
              :options="editorOpions"
              @change="onEditorChangeFooter($event)"
            />
          </div>
        </div>
        <div class="other-content flex justify-between mb-5 hidden">
          <div>
            <label class="mr-5 text-xl font-semibold text-blackdok"
              >Votre signature</label
            >
            <div
              class="w-full flex flex-col justify-center items-center mt-5 px-2"
            >
              <upload-image
                :param-file.sync="doctorProfile.prescription.signature"
                :active-upload="true"
                type="signature"
              ></upload-image>
            </div>
          </div>
          <div>
            <label class="mr-5 text-xl font-semibold text-blackdok"
              >Votre Tampon</label
            >
            <div
              class="w-full flex flex-col justify-center items-center mt-5 px-2"
            >
              <upload-image
                :param-file.sync="doctorProfile.prescription.stamp"
                :active-upload="true"
                type="stamp"
              ></upload-image>
            </div>
          </div>
        </div>
        <div class="flex justify-end gap-x-3 py-2">
          <button
            @click="previewOrdonnance"
            class="
            border-2 border-solid border-green-600
            text-green-600
            hover:text-white
            bg-transparent
            hover:bg-green-600
            py-3
            px-3
            rounded-cu
            transition
            delay-150
            duration-300
            ease-in-out
            font-EffraR
            cursor-pointer
            w-full
            xl:w-auto
            hidden
          "
          >
            Aperçu
          </button>
          <button
            @click="saveOrdonnance"
            class="
            border-2 border-solid border-dokBlue-ultra
            text-dokBlue-ultra
            hover:text-white
            bg-transparent
            hover:bg-dokBlue-ultra
            py-3
            px-3
            rounded-cu
            transition
            delay-150
            duration-300
            ease-in-out
            cursor-pointer
            font-EffraR
            w-full
            xl:w-auto
          "
          >
            Sauvegarder
          </button>
        </div>
      </div>
      <modal
        title="Preview Ordonnance"
        :max-height="850"
        :height="850"
        :width="600"
        :min-height="700"
        :is-active="showPreview"
        :exit-modal="exitModal"
      >
        <div
          id="priewPrescHtml"
          class="h-full w-full"
          v-html="previewContent"
        ></div>
      </modal>
    </template>

    <!-- LOADER -->

    <div class="flex items-center justify-center py-24" v-if="loader">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="70" cy="50" fill="#2e6bff" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
const avatarUpload = () => import("@/views/global-components/uploadAvatar.vue");
const uploadImage = () => import("@/views/global-components/uploadImage.vue");
const modal = () => import("@/views/global-components/modal.vue");
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css";

export default {
  components: {
    quillEditor,
    modal,
    avatarUpload,
    uploadImage
  },
  data() {
    return {
      editorOpions: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ["link", "image"]
          ]
        }
      },
      showPreview: false,
      previewContent: "",
      doctorProfile: {
        logo: "",
        prescription: {
          footer: "",
          header: ""
        }
      },
      // Filds Add By Nazih Adberrahman
      loader: true
    };
  },
  async created() {
    let doctor = await this.$auth.getUserInfo();
    this.$store.dispatch("doctor/GET_BY_ID", {
      onData: this.onDataMount,
      id: doctor._id
    });
  },
  methods: {
    async onDataMount({ ok, data }) {
      let doctor = await this.$auth.getUserInfo();
      this.doctorProfile = data.doctorProfile;

      this.doctorProfile.prescription = this.doctorProfile.prescription
        ? this.doctorProfile.prescription
        : {
            footer: "",
            header: ""
          };

      if (
        !this.doctorProfile ||
        !this.doctorProfile.prescription ||
        this.doctorProfile.prescription.header == null ||
        !this.doctorProfile.prescription.footer == null
      ) {
        if (this.doctorProfile.prescription.header == null) {
          var breakTag = "<br />";
          this.doctorProfile.prescription.header =
            "<p>" +
            (this.doctorProfile.experienceDiplomes || "").replace(
              /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
              "$1" + breakTag + "$2"
            ) +
            "</p><br><p></p><p>" +
            (this.doctorProfile.experienceBackground || "").replace(
              /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
              "$1" + breakTag + "$2"
            ) +
            "</p>";
        }

        if (this.doctorProfile.prescription.footer == null) {
          this.doctorProfile.prescription.footer = `<p>${doctor.landingPage.address.label}, ${doctor.landingPage.address.city.name}<br><b>tel</b>: ${doctor.fix}</p>`;
        }

        this.saveOrdonnance();
      }

      // Loader False
      this.$nextTick(() => {
        this.loader = false;
      });
    },
    onEditorChangeHeader({ quill, html, text }) {
      this.doctorProfile.prescription.header = html;
    },
    onEditorChangeFooter({ quill, html, text }) {
      this.doctorProfile.prescription.footer = html;
    },
    saveOrdonnance() {
      this.$store.dispatch("doctor/SAVE_PROFILE_ORDONNANCE", {
        params: {
          logo: this.doctorProfile.logo,
          prescription: {
            header: this.doctorProfile.prescription.header,
            footer: this.doctorProfile.prescription.footer,
            stamp: this.doctorProfile.prescription.stamp,
            signature: this.doctorProfile.prescription.signature
          }
        },
        onData: this.onData
      });
    },
    previewOrdonnance(data) {
      // //console.log(this.doctorProfile);
      this.$store.dispatch("doctor/PREVIEW_MY_MODEL", {
        params: {
          logo: this.doctorProfile.logo,
          header: this.doctorProfile.prescription.header,
          footer: this.doctorProfile.prescription.footer
        },
        onData: this.onPreviwData
      });
    },
    onPreviwData(data) {
      this.previewContent = data;
      this.showPreview = true;
    },
    exitModal() {
      this.showPreview = false;
    },
    onData(data) {
      const _this = this;
      return EventBus.$emit("flashDok", {
        type: data.ok ? "success" : "danger",
        message: data.ok
          ? "Mis à jour avec succès"
          : "Erreur de sauvegarde. Veuillez réessayer."
      });

      this.$store.dispatch("user/GET_PROFILE", {
        onData: () => {
          _this.user = _this.$auth.getUserInfo();
        }
      });
    }
  }
};
</script>

<style lang="scss">
// #priewPrescHtml {
//   footer {
//     // position: absolute;
//     bottom: 0;
//     width: 100%;
//   }
// }
</style>
